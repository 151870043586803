import { Row, Col } from "react-bootstrap";
import React from "react";
import { Trans } from "react-i18next";
import { features } from "../utilities/constants";
import styled from "styled-components";

const ChoosePhoneboxSlideShow: React.FC = () => {
  return (
    <ChoosePhoneboxSliderShowRow>
      {features.map((item, i) => (
        <ChoosePhoneboxSliderShowCol md={12} xl={3} lg={6} sm={12} key={i}>
          <ChoosePhoneboxSliderOfferCard data-aos="zoom-in">
            <ChoosePhoneboxSliderImage src={item.image} />
            <ChoosePhoneboxSliderTitle>
              <Trans>{item.title}</Trans>
            </ChoosePhoneboxSliderTitle>
            <div>
              <Trans>{item.description}</Trans>
            </div>
            <br />
            <div>
              <Trans>{item.extraDescription}</Trans>
            </div>
          </ChoosePhoneboxSliderOfferCard>
        </ChoosePhoneboxSliderShowCol>
      ))}
    </ChoosePhoneboxSliderShowRow>
  );
};

const ChoosePhoneboxSliderShowRow = styled(Row)`
  margin: 30px 70px;
  
  @media (max-width: 1366px) {
    margin: 30px;
  }

  @media (max-width: 1024px) {
    margin: 15px;
  }
`;

const ChoosePhoneboxSliderShowCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 10px 30px;

  @media (max-width: 1366px) {
    padding: 10px 25px;
  }
`;

const ChoosePhoneboxSliderOfferCard = styled.div`
  margin-top: 85px;
  @media (max-width: 1366px) {
    margin-top: 60px;
  }
`;

const ChoosePhoneboxSliderImage = styled.img`
  width: 150px;
  height: 80px;
  object-fit: contain;

  @media (max-width: 1366px) {
    width: 100px;
    height: 80px;
    object-fit: contain;
  }
`;

const ChoosePhoneboxSliderTitle = styled.div`
  text-align: center;
  font: normal normal bold 22px/30px Poppins;
  letter-spacing: 0.18px;
  color: #333333;
  margin-bottom: 25px;
  margin-top: 25px;
  min-height: 60px;

  @media (max-width: 1366px) {
    font: normal normal bold 18px/30px Poppins;
    margin-bottom: 0px;
    margin-top: 35px;
  }
`;

export default ChoosePhoneboxSlideShow;
