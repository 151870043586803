import React from "react";
import "./MainCarouselFancyTitle.scss";
import { Trans } from "react-i18next";

interface MainCarouselFancyTitleProps {
  text: string;
}

const MainCarouselFancyTitle: React.FC<MainCarouselFancyTitleProps> = (props: MainCarouselFancyTitleProps) => {
  return (
    <>
      <h1 className="mc-fancy-title">
        <Trans>{props.text}</Trans>
      </h1>
    </>
  );
};

export default MainCarouselFancyTitle;