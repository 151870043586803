import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components";

interface PriceDisplayProps {
  price: number;
  timeUnit: string;
  data: number;
  type: string;
}

const CustomTextOverlay: React.FC<PriceDisplayProps> = (props: PriceDisplayProps) => {
  return (
    <>
      {props.type === "OurBestDeal" &&
        <Container>
          <Title>
            <Trans>90 Days Plan</Trans>
          </Title>
          <Information>
            <InformationData>{props.data}<Trans>GB</Trans></InformationData>
            <InformationLine>|</InformationLine>
            <PriceContainer>
              <PriceWrapper>
                <PriceCurrencySymbol>$</PriceCurrencySymbol>
                <PriceCost>{props.price}</PriceCost>
              </PriceWrapper>
              {/* <PriceTimeUnit>
                <Trans>{props.timeUnit}</Trans>
              </PriceTimeUnit> */}
              <div style={{ 
                backgroundColor: "#e13e26", 
                borderRadius: "10px", 
                font: "normal normal bold 8px Poppins", 
                padding: "0px 4px", 
                height: "10px",
                marginBottom: "30px"
              }}>
                CAD
              </div>
            </PriceContainer>
          </Information>
          <MoreInformation>
            <Trans>Get your phone plan</Trans>
          </MoreInformation>
          <OutlineInformation>
            <Trans>& stay connected</Trans>
          </OutlineInformation>
        </Container>
      }
      {props.type === "SpecialEsimDeal" &&
        <EsimContainer>
          <EsimSmallTxt>
            <Trans>Tap into Simplicity!</Trans>
          </EsimSmallTxt>
          <EsimMainTxt>
            <Trans
              defaults={`Switch to <EsimHighlight>eSIM</EsimHighlight>`}
              components={{
                EsimHighlight: <EsimHighlight />
              }}
            />
          </EsimMainTxt>
          <EsimMainTxt>
            <Trans>for Faster Connection!</Trans>
          </EsimMainTxt>
        </EsimContainer>
      }
    </>
  );
};

const PriceContainer = styled.div`
  display: flex;
  align-items: end;
`;

const PriceWrapper = styled.div`
  display: flex;
  align-items: start;
`;

const PriceCurrencySymbol = styled.div`
  font: normal normal bold 10px/30px Poppins;
  color: #000;
`;

const PriceCost = styled.div`
  font: normal normal bold 30px Poppins;
  color: #fff;
  -webkit-text-stroke: 2px black;
  text-stroke: 2px black;
`;


const PriceTimeUnit = styled.div`
  font: normal normal normal 10px Poppins;
  color: #fff;
  margin-bottom: 10px;
`;

const Container = styled.div`
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  // border: 1px solid black; 
  width: 273px;
  height: 185.1px;
  color: white;
  padding: 15px;
`;

const Title = styled.div`
  display: inline-block;
  border: 2px solid white;
  padding: 5px 15px;
  margin-bottom: 12px;
  @media (min-width: 768px) and (max-width: 991px) {
    margin-bottom: 6px;
    font-size: 15px;
  }
`;

const InformationData = styled.div`
  font: normal normal bold 30px Poppins;
  -webkit-text-stroke: 2px black;
  text-stroke: 2px black;
`;

const InformationLine = styled.div`
  font: normal normal normal 30px Poppins;
  -webkit-text-stroke: 2px black;
  text-stroke: 2px black;
`;

const Information = styled.div`
  display: flex;
  justify-content: center;
  align-items: end;
  gap: 20px;
  margin-top: 5px;
  margin-bottom: 8px;

  @media (min-width: 768px) and (max-width: 991px) {
    gap: 10px;
    margin-bottom: 0;
    margin-top: -5px;
  }
`;

const MoreInformation = styled.div`
  color: white;
  font: normal normal bold 17px/17px Poppins;
  padding-top: 5px;
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 12px;
    text-align: center;
    padding: 0 20px;
  }
`;

const OutlineInformation = styled.div`
  font: normal normal 900 17px Poppins;
  color: rgba(0,0,0,0);
  -webkit-text-stroke: 0.7px white;
  text-stroke: 0.7px white;
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 12px;
    text-align: center;
    padding: 0 20px;
  }
`;

const EsimContainer = styled.div`
  position: absolute;
  margin-top: 0;
  margin-bottom: 0;
  top: 35px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 273px;
  height: 185.1px;
  color: white;

  @media (min-width: 768px) and (max-width: 991px) {
    width: 216px;
  }
`;

const EsimSmallTxt = styled.div`
  width: fit-content;
  padding: 0 5px;
  border-radius: 0 7px 7px 0;
  background-color: #fff;
  margin-bottom: 16px;
  font: normal normal bold 16px/30px Poppins;
  color: #0494ca;

  @media (min-width: 768px) and (max-width: 991px) {
    font: normal normal bold 12px/20px Poppins;
  }
`;

const EsimMainTxt = styled.div`
  font: normal normal bold 22px/28px Poppins;
  padding-left: 8px;

  @media (min-width: 768px) and (max-width: 991px) {
    font: normal normal bold 16px/20px Poppins;
  }
`;

const EsimHighlight = styled.span`
 -webkit-text-stroke: 1.5px #e13e26;
`;


export default CustomTextOverlay;
