import React from "react";
import "./MainCarouselPriceBox.scss";
import { Trans } from "react-i18next";

interface MainCarouselPriceBoxProps {
  text: string;
  price: number;
}

const MainCarouselPriceBox: React.FC<MainCarouselPriceBoxProps> = (props: MainCarouselPriceBoxProps) => {
  return (
    <>
      <div className="mc-price-box">
        <div className="mc-price-box-text">
          <Trans>{props.text}</Trans>
        </div>
        <div className="mc-price-box-price">
          ${props.price}
        </div>
      </div>
    </>
  );
};

export default MainCarouselPriceBox;
