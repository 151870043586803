import React from "react";
import "./MainCarouselSubtitleDescription.scss";
import { Trans } from "react-i18next";

interface MainCarouselSubtitleDescriptionProps {
  text: string;
}

const MainCarouselSubtitleDescription: React.FC<MainCarouselSubtitleDescriptionProps> = (props: MainCarouselSubtitleDescriptionProps) => {
  return (
    <>
      <h4 className="mc-subtitle-description">
        <Trans>{props.text}</Trans>
      </h4>
    </>
  );
};

export default MainCarouselSubtitleDescription;
