import { constants } from "../constants";

import instance from "../../utilities/axios.js";

export const getCountryCodes = () => (dispatch) => {
  instance
    .get("/Activation/GetCountries")
    .then((response) => {
      return dispatch({
        type: constants.GET_COUNTRY_CODES,
        payload: response.data,
      });
    })
    .catch((error) => {
        console.error(error);
    });
};
