import React from "react";
import "./MainCarouselTitleDescription.scss";
import { Trans } from "react-i18next";

interface MainCarouselTitleDescriptionProps {
  text: string;
  textBold: string
}

const MainCarouselTitleDescription: React.FC<MainCarouselTitleDescriptionProps> = (props: MainCarouselTitleDescriptionProps) => {
  return (
    <>
      <h2 className="mc-title-description">
        <Trans>{props.text}</Trans>
        <span className="mc-title-description-bold">
          <Trans>{props.textBold}</Trans>
        </span>
      </h2>
    </>
  );
};

export default MainCarouselTitleDescription;
