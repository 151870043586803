import React from "react";
import "./MainCarouselPlansButton.scss";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightLong } from "@fortawesome/free-solid-svg-icons";

interface MainCarouselPlansButtonProps {
  text: string;
  onClick?: React.MouseEventHandler;
  className?: string;
}

const MainCarouselPlansButton: React.FC<MainCarouselPlansButtonProps> = (props: MainCarouselPlansButtonProps) => {
  return (
    <>
      <span className={props.className}>
        <a className="mc-plans-button" onClick={props.onClick}>
          <span className="mc-plans-button-text">
            <Trans>{props.text}</Trans>
          </span>
          <span className="mc-plans-button-icon">
            <FontAwesomeIcon icon={faRightLong} />
          </span>
        </a>
      </span>
    </>
  );
};

export default MainCarouselPlansButton;
