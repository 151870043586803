import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components";

const PlanExplainBox: React.FC = () => {
  return (
    <PlanExplainerMainRow>
      <PlanExplainer>
        <PlanExplainerContent>
          <PlanExplainerHeading>
            <Trans>Plan Includes:</Trans>
          </PlanExplainerHeading>
          <PlanExplainerParagraph>
            <b>
              <Trans>Unlimited</Trans>
            </b>{" "}
            <Trans>Nation-wide calling and Text messaging</Trans>
          </PlanExplainerParagraph>
          <PlanExplainerParagraph >
            <b>
              <Trans>Unlimited</Trans>
            </b>{" "}
            <Trans>
              International Text message, Picture messaging, and Call display
            </Trans>
          </PlanExplainerParagraph>
        </PlanExplainerContent>
        <PlanExplainerImageContainer>
          <PlanExplainerImage src="/images/textingLady.png" />
        </PlanExplainerImageContainer>
      </PlanExplainer>
    </PlanExplainerMainRow>
  );
};

const PlanExplainerMainRow = styled.div`
  margin: 30px 160px;
  
  @media (max-width: 1024px) {
    margin: 30px 0px;
  }
`;

const PlanExplainer = styled.div`
  background: #ECFAFF 0% 0% no-repeat padding-box;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  
  @media (max-width: 1024px) {
    border-radius: 0;
  }
`;

const PlanExplainerContent = styled.div`
  padding: 30px 40px;
  
  @media (max-width: 1366px) {
    padding: 25px 35px;
  }

`;

const PlanExplainerHeading = styled.div`
  text-align: left;
  font: normal normal bold 22px/30px Poppins;
  letter-spacing: 0.18px;
  color: #0494ca;

  @media (max-width: 1366px) {
    font: normal normal bold 18px/30px Poppins;
  }
`;

const PlanExplainerParagraph = styled.div`
  text-align: left;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0.4px;
  color: #0494CA;
  
  @media (max-width: 1366px) {
    font: normal normal normal 16px/22px Poppins;
    padding: 0.3rem 0;
  }
`;

const PlanExplainerImageContainer = styled.div`
  margin-top: -100px;
  margin-right: 50px;

  @media (max-width: 1134px) {
    display: none;
  }
`;

const PlanExplainerImage = styled.img`
  max-width: 180px;
  width: 100%;
`;

export default PlanExplainBox;
