import React from "react";
import "./MainCarouselTitle.scss";
import { Trans } from "react-i18next";

interface MainCarouselTitleProps {
  text: string;
}

const MainCarouselTitle: React.FC<MainCarouselTitleProps> = (props: MainCarouselTitleProps) => {
  return (
    <>
      <h1 className="mc-title">
        <Trans>{props.text}</Trans>
      </h1>
    </>
  );
};

export default MainCarouselTitle;
