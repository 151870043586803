import axios from "axios";

// a 204 response will be returned if there is no data.
// abstract API will stop working after 20,000 requests and instead return a 403 error.
// 429 error code appears if there are too many requests per second.
// in these cases, set banner to international deals and leave country code as default.
// read more: https://app.abstractapi.com/api/ip-geolocation/documentation

export const getCurrentCountry = async ():Promise<any> => {
  return await axios({
    method: 'get',
    url: `https://ipgeolocation.abstractapi.com/v1/?api_key=8a86de5fec574c03aaf277ba1f9fc4d7`
  });
};