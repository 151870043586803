import React from "react";
import "./MainCarouselSubtitle.scss";
import { Trans } from "react-i18next";

interface MainCarouselSubtitleProps {
  text: string;
}

const MainCarouselSubtitle: React.FC<MainCarouselSubtitleProps> = (props: MainCarouselSubtitleProps) => {
  return (
    <>
      <h3 className="mc-subtitle">
        <Trans>{props.text}</Trans>
      </h3>
    </>
  );
};

export default MainCarouselSubtitle;
